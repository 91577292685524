import Rest from "@/services/api_config/RestApi";
import RealtimeDashboard from "@/services/api_config/RealtimeDashboardApi";

export default {
  getPages(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    return Rest().get("wayko/com/sub/page?" + data.substring(1));
  },
  getPage(id) {
    return Rest().get("wayko/com/sub/page/" + id);
  },
  addPage(data) {
    return Rest().put("wayko/com/sub/page/add", data);
  },
  editPage(id, data) {
    return Rest().patch("wayko/com/sub/page/" + id, data);
  },
  deletePage(id) {
    return Rest().delete("wayko/com/sub/page/" + id);
  },
  getModules(params) {
    let data = ``;
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.page_id !== null &&
      params.page_id !== undefined &&
      params.page_id !== ""
    ) {
      data += `&page_id=${params.page_id}`;
    }

    return Rest().get("wayko/com/sub/module?" + data.substring(1));
  },
  getEnrollVehicles(data) {
    // let data = ``;
    // if (
    //   params.company_id !== null &&
    //   params.company_id !== undefined &&
    //   params.company_id !== ""
    // ) {
    //   data += `&company_id=${params.company_id}`;
    // }

    // return Rest().post("wayko/com/get-enroll-vehicles?" + data.substring(1));
    return Rest().post("outlets/wayko/com/sub/get-enroll-vehicles?", data);
  },
  addModule(data) {
    return Rest().put("wayko/com/sub/module/add", data);
  },
  editModule(data, id) {
    return Rest().patch("wayko/com/sub/module/" + id, data);
  },
  deleteModule(id) {
    return Rest().delete("wayko/com/sub/module/" + id);
  },
  getEmployees(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }
    return Rest().get("wayko/com/sub/employee?" + data.substring(1));
  },
  getEmployee(id) {
    return Rest().get("wayko/com/sub/employee/" + id);
  },
  addEmployee(data) {
    return Rest().put("wayko/com/sub/employee/add", data);
  },
  assignEmployee(id, data) {
    return Rest().patch("wayko/com/sub/employee/assign-employee/" + id, data);
  },
  uploadEmployee(data) {
    return Rest().put("wayko/com/sub/employee/upload", data);
  },
  editEmployee(id, data) {
    return Rest().patch("wayko/com/sub/employee/" + id, data);
  },
  deleteEmployee(id) {
    return Rest().delete("wayko/com/sub/employee/" + id);
  },
  resetPassword(id) {
    return Rest().patch("wayko/com/sub/employee/reset-password/" + id);
  },
  getRoles(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }
    return Rest().get("wayko/com/sub/role?" + data.substring(1));
  },
  getEmployeesAssign(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    return Rest().get("wayko/com/employee/assign?" + data.substring(1));
  },
  getRole(id) {
    return Rest().get("wayko/com/sub/role/" + id);
  },
  addRole(data) {
    return Rest().put("wayko/com/sub/role/add", data);
  },
  editRole(data, id) {
    return Rest().patch("wayko/com/sub/role/" + id, data);
  },
  deleteRole(id) {
    return Rest().delete("wayko/com/sub/role/" + id);
  },
  getVehicles(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.order !== null &&
      params.order !== undefined &&
      params.order !== ""
    ) {
      data += `&order=${params.order}`;
    }
    if (
      params.desc !== null &&
      params.desc !== undefined &&
      params.desc !== ""
    ) {
      data += `&desc=${params.desc}`;
    }
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.tracking_admin_user !== null &&
      params.tracking_admin_user !== undefined &&
      params.tracking_admin_user !== ""
    ) {
      data += `&tracking_admin_user=${params.tracking_admin_user}`;
    }
    return Rest().get("wayko/com/sub/vehicle?" + data.substring(1));
  },
  getVehicle(id) {
    return Rest().get("wayko/com/sub/vehicle/" + id);
  },
  addVehicle(data) {
    return Rest().put("wayko/com/sub/vehicle/add", data);
  },
  editVehicle(id, data) {
    return Rest().patch("wayko/com/sub/vehicle/" + id, data);
  },
  deleteVehicle(id, data) {
    return Rest().delete("wayko/com/sub/vehicle/" + id, data);
  },
  getVehicleStatus(id) {
    return Rest().get("wayko/com/sub/vehicle-status/" + id);
  },
  updateStatus(id, data) {
    return Rest().patch("wayko/com/sub/vehicle-update-status/" + id, data);
  },
  uploadImages(data) {
    return Rest().put("wayko/com/sub/vehicle/upload-images", data);
  },
  getImages(params) {
    let data = ``;
    if (
      params.vehicle_id !== null &&
      params.vehicle_id !== undefined &&
      params.vehicle_id !== ""
    ) {
      data += `&vehicle_id=${params.vehicle_id}`;
    }

    return Rest().get("wayko/com/sub/vehicle/get-images?" + data.substring(1));
  },
  hideVehiclesFromCompany(data) {
    return Rest().put("wayko/com/sub/hide-vehicles-from-company", data);
  },
  getOutlets(params) {
    let data = ``;
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }
    if (
      params.type !== null &&
      params.type !== undefined &&
      params.type !== ""
    ) {
      data += `&type=${params.type}`;
    }
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    return Rest().get("wayko/com/sub/outlets?" + data.substring(1));
    // return Rest().get("outlets/wayko/com/sub/outlets?" + data.substring(1));
  },
  addOutlet(data) {
    return Rest().put("wayko/com/sub/outlet", data);
    // return Rest().put("outlets/wayko/com/sub/outlet", data);
  },
  editOutlet(data, id) {
    return Rest().patch("wayko/com/sub/outlet/" + id, data);
    // return Rest().patch("outlets/wayko/com/sub/outlet/" + id, data);
  },
  deleteOutlet(id) {
    return Rest().delete("wayko/com/sub/outlet/" + id);
    // return Rest().delete("outlets/wayko/com/sub/outlet/" + id);
  },
  getOutletsMonitoring(params) {
    let data = ``;
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }
    return Rest().get("wayko/com/sub/outlets-monitoring?" + data.substring(1));
  },
  getTrackingDispatchMonitoringDispatches(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.order !== null &&
      params.order !== undefined &&
      params.order !== ""
    ) {
      data += `&order=${params.order}`;
    }
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }
    if (
      params.appointment_date !== null &&
      params.appointment_date !== undefined &&
      params.appointment_date !== ""
    ) {
      data += `&appointment_date=${params.appointment_date}`;
    }
    if (
      params.current_module !== null &&
      params.current_module !== undefined &&
      params.current_module !== ""
    ) {
      data += `&current_module=${params.current_module}`;
    }

    // return Rest().get(
    //   "wayko/com/sub/dispatch-monitoring/sub-company-dispatches?" +
    //     data.substring(1)
    // );
    return Rest().get(
      "outlet-dispatch/wayko/com/sub/dispatch-monitoring/company-dispatches?" +
        data.substring(1)
    );
  },
  getTrackingActiveMonitoringDispatches(params) {
    let data = ``;
    if (
      params.limit !== null &&
      params.limit !== undefined &&
      params.limit !== ""
    ) {
      data += `&limit=${params.limit}`;
    }
    if (
      params.offset !== null &&
      params.offset !== undefined &&
      params.offset !== ""
    ) {
      data += `&offset=${params.offset}`;
    }
    if (
      params.search !== null &&
      params.search !== undefined &&
      params.search !== ""
    ) {
      data += `&search=${params.search}`;
    }
    if (
      params.order !== null &&
      params.order !== undefined &&
      params.order !== ""
    ) {
      data += `&order=${params.order}`;
    }
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }
    if (
      params.appointment_date !== null &&
      params.appointment_date !== undefined &&
      params.appointment_date !== ""
    ) {
      data += `&appointment_date=${params.appointment_date}`;
    }
    if (
      params.current_module !== null &&
      params.current_module !== undefined &&
      params.current_module !== ""
    ) {
      data += `&current_module=${params.current_module}`;
    }

    return Rest().get(
      "wayko/com/sub/active-monitoring/sub-company-dispatches?" +
        data.substring(1)
    );
  },
  saveDispatchMonitoringDispatch(data) {
    // return Rest().post("wayko/com/dispatch-monitoring/upload-dispatches", data);
    return Rest().post(
      "outlet-dispatch/save/wayko/com/sub/dispatch-monitoring/upload-dispatches",
      data
    );
  },
  cancelDispatchMonitoringDispatch(id) {
    // return Rest().patch("wayko/com/dispatch-monitoring/cancel-dispatch/" + id);
    return Rest().patch(
      "outlet-dispatch/cancel/wayko/com/sub/dispatch-monitoring/cancel-dispatch/" +
        id
    );
  },
  closeDispatchMonitoringDispatch(id) {
    // return Rest().patch("wayko/com/dispatch-monitoring/close-dispatch/" + id);
    return Rest().patch(
      "outlet-dispatch/close/wayko/com/sub/dispatch-monitoring/close-dispatch/" +
        id
    );
  },
  getDashboard(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }
    if (
      params.tracking_admin_user !== null &&
      params.tracking_admin_user !== undefined &&
      params.tracking_admin_user !== ""
    ) {
      data += `&tracking_admin_user=${params.tracking_admin_user}`;
    }

    return Rest().get("wayko/com/sub/tracking-dashboard?" + data.substring(1));
  },
  getDashboardDetails(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }
    if (
      params.tracking_admin_user !== null &&
      params.tracking_admin_user !== undefined &&
      params.tracking_admin_user !== ""
    ) {
      data += `&tracking_admin_user=${params.tracking_admin_user}`;
    }

    return Rest().get(
      "wayko/com/sub/tracking-dashboard-details?" + data.substring(1)
    );
  },
  getDashboardApi(params) {
    let data = ``;
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }

    return RealtimeDashboard().get(
      "dashboard/wayko/com/sub/tracking-dashboard-api?" + data.substring(1)
    );
    // return Rest().get(
    //   "realtime-socket/wayko/com/tracking-dashboard-details?" +
    //     data.substring(1)
    // );
  },
  getDashboardMapApi(params) {
    let data = ``;
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }

    return RealtimeDashboard().get(
      "map/wayko/com/sub/tracking-dashboard-api?" + data.substring(1)
    );
    // return Rest().get(
    //   "realtime-socket/wayko/com/tracking-dashboard-details?" +
    //     data.substring(1)
    // );
  },
  getDashboardRunningVehicles(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }

    return Rest().get(
      "wayko/com/sub/tracking-dashboard-running-vehicles?" + data.substring(1)
    );
  },
  getConfig(id) {
    return Rest().get("wayko/com/sub/sub-company-settings/" + id);
  },
  setConfig(data) {
    return Rest().post("wayko/com/sub/sub-company-settings", data);
  },
  disconnectSocketConnection() {
    return Rest().post("wayko/com/sub/tracking-dashboard-disconnect-socket");
  },
  getGPSDevices(params) {
    let data = ``;
    if (
      params.company_id !== null &&
      params.company_id !== undefined &&
      params.company_id !== ""
    ) {
      data += `&company_id=${params.company_id}`;
    }
    if (
      params.sub_company_id !== null &&
      params.sub_company_id !== undefined &&
      params.sub_company_id !== ""
    ) {
      data += `&sub_company_id=${params.sub_company_id}`;
    }
    if (
      params.dialog_type !== null &&
      params.dialog_type !== undefined &&
      params.dialog_type !== ""
    ) {
      data += `&dialog_type=${params.dialog_type}`;
    }
    return Rest().get("wayko/com/sub/gps-devices?" + data.substring(1));

    // return Rest().get("wayko/com/sub/gps-devices?" + data.substring(1));
  },
};
